import Table from "Components/Table";
import React from "react";
import { useSelector } from "react-redux";

function SimahExisting() {
  const codes = useSelector((state) => state.getSimahCodes);

  return (
    <div className="  w-full">
      <div className="overflow-x-auto relative">
        <Table header={header} styleMain={"mt-0"}>
          <tbody className="dark:text-dark0">
            {codes?.["Existing"]?.map((v, k) => (
              <tr
                key={k}
                className={`border-b   border-gray-100 dark:border-dark1 ${
                  k % 2
                    ? "bg-gray-50 dark:bg-gray-600"
                    : "bg-white dark:bg-gray-700"
                }`}
              >
                <td
                  scope="row"
                  className="px-10 py-4 flex flex-row space-x-3 items-center rtl:space-x-reverse"
                >
                  <a>{v.id}</a>
                </td>

                <td className="px-3 py-4">{v.code}</td>
                <td className="px-3 py-4">{v.product}</td>
                <td className="px-3 py-4">{v.simahDesc}</td>
                <td className="px-3 py-4">{v.productGroup}</td>
                <td className="px-3 py-4">{v.issuer}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
export default SimahExisting;

let header = [
  { name: "Id" },
  { name: "Code" },
  { name: "Product" },
  { name: "Simah Description" },
  { name: "Product Group" },
  { name: "Issuer" },
];
