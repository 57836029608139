import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CardMain from "../../../Components/Cards/main";
import { useDispatch, useSelector } from "react-redux";
import User from "./CreateUserModel";
import Model2 from "Components/Model2";
import withAuthorization from "../../../constants/authorization";
import { getLanguage } from "functions/getLanguage";
import Table from "Components/Table";

function CreateNewAdmin() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.getAllUsersAll || []);

  const [usersData, setUsersData] = useState([]);

  const [modelOpen, setModelOpen] = useState(false);

  useEffect(() => {
    getAllNotifictions();
  }, []);
  function getAllNotifictions() {
    dispatch({
      type: "GET_ALL_USERS_ALL",
    });
  }
  function reset() {
    setModelOpen(false);
  }

  useEffect(() => {
    if (users) {
      const data = users.filter((person) => person?.roles[0]?.name !== "User");
      setUsersData(data);
    }
  }, [users]);

  let header = [
    { name: "First Name" },
    { name: "Email" },
    { name: "Username" },
    { name: "Mobile Number" },
    { name: "Role" },
  ];
  return (
    <div className="py-5">
      <CardMain
        width="w-full"
        heading={t("All Admins and Moderators")}
        showButton={true}
        buttonValue={t("Add New User")}
        onButtonClick={() => setModelOpen(true)}
      >
        <div className="overflow-x-auto relative  ">
          <Table header={header}>
            <tbody className="dark:text-dark0">
              {usersData?.map((v, k) => (
                <tr
                  key={k}
                  className={`border-b   border-gray-100 dark:border-dark1 ${
                    k % 2
                      ? "bg-gray-50 dark:bg-gray-600"
                      : "bg-white dark:bg-gray-700"
                  }`}
                >
                  <td
                    scope="row"
                    className="px-3 py-4 flex flex-row space-x-3 items-center rtl:space-x-reverse"
                  >
                    {v?.firstName + " " + v?.lastName}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.email}
                  </td>
                  <td className="px-3 py-4">{v?.idNumber}</td>
                  <td className="px-3 py-4">{v?.mobileNumber}</td>
                  <td className="px-3 py-4">{v?.roles[0]?.name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardMain>

      {modelOpen ? (
        <Model2
          setModelOpen={(e) => setModelOpen(e)}
          reset={() => reset()}
          heading="Add User"
        >
          <User
            setModel={(e) => setModelOpen(e)}
            getAllUsers={() => getAllNotifictions()}
          />
        </Model2>
      ) : null}
    </div>
  );
}

export default withAuthorization(CreateNewAdmin);
