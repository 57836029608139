import React, { useState, useEffect } from "react";
import CardMain from "Components/Cards/main";
import { useTranslation } from "react-i18next";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getLanguage } from "functions/getLanguage";
import Table from "Components/Table";

function ViewQuestions({ getAllQuestion }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const questionsData = useSelector((state) => state.getAllQuestions);

  const DeleteQuestion = (id) => {
    dispatch({
      type: "DELETE_QUESTION",
      id,
    });
    setTimeout(() => getAllQuestion(), 500); // AFTER ADDING QUESTION TO DATABASE , GETTING NEW LIAST OF QUESTIONS
  };
  let header = [
    { name: "Heading English" },
    { name: "Heading Arabic" },
    { name: "Question English" },
    { name: "Question Arabic" },
    { name: "Type" },
    { name: "Field / Options" },
    { name: "Action" },
  ];
  return (
    <div className="">
      <CardMain
        heading={t("Questions List")}
        width="w-full  h-max mt-4 md:mt-5"
      >
        <div className="w-full flex flex-col overflow-x-auto">
          <Table header={header}>
            <tbody className="dark:text-dark0">
              {questionsData?.map((v, k) => (
                <tr
                  key={k}
                  className={`border-b   border-gray-100 dark:border-dark1 ${
                    k % 2
                      ? "bg-gray-50 dark:bg-gray-600"
                      : "bg-white dark:bg-gray-700"
                  }`}
                >
                  <td className="px-3 py-3">{v?.heading}</td>
                  <td className="px-3 py-3 amiri-regular">{v?.headingAr}</td>
                  <td className="px-3 py-3 overflow-wrap ">{v?.question}</td>
                  <td className="px-3 py-3 overflow-wrap amiri-regular">
                    {v?.questionAr}
                  </td>
                  <td className="px-3 py-3">{v?.type}</td>
                  <td className="px-3 py-3">
                    {}
                    {v?.options[0]?.optionEn ? (
                      <>
                        {v?.options?.map((s) => {
                          return (
                            <div>
                              <div> {s?.optionEn + " , " + s?.optionAr}</div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <a>
                        {" "}
                        {v?.options?.length > 0
                          ? v?.options?.join(", ")
                          : v?.field}
                      </a>
                    )}
                  </td>
                  <td
                    className="px-3 py-3 text-2xl cursor-pointer text-gray-400 hover:text-red-400 duration-300"
                    onClick={() => DeleteQuestion(v?.id)}
                  >
                    <MdDelete />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardMain>
    </div>
  );
}
export default ViewQuestions;
