import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CardMain from "Components/Cards/main";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { getLanguage } from "functions/getLanguage";
import withAuthorization from "constants/authorization";
import Table from "Components/Table";

function CreateQuestionsSet() {
  const dispatch = useDispatch();
  const getScreensSets = useSelector((state) => state.getScreensSets.data);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  useEffect(() => {
    GetScreenSets();
  }, []);

  function GetScreenSets() {
    dispatch({
      type: "GET_SCEENS_SET",
      payload: id,
    });
  }

  return (
    <div className="">
      {getScreensSets && (
        <div className="mt-6 flex flex-wrap md:flex-wrap   rtl:space-x-reverse h-max">
          {Object.entries(getScreensSets).map(([category, items]) => (
            <div className="p-2 md:w-1/3 w-full h-min md:flex-shrink-0">
              <CardMain key={category} heading={category} width=" ">
                <Table header={header} styleMain={"mt-0"}>
                  <tbody className="dark:text-dark0">
                    {items?.map((v, k) => (
                      <tr
                        key={k}
                        className={`border-b   border-gray-100 dark:border-dark1 ${
                          k % 2
                            ? "bg-gray-50 dark:bg-gray-600"
                            : "bg-white dark:bg-gray-700"
                        }`}
                      >
                        <td className="px-3 py-4 cursor-pointer text-primary">
                          {getLanguage() === "ar" ? (
                            <>{v?.data?.questionArabic}</>
                          ) : (
                            <>{v?.data?.question}</>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardMain>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default withAuthorization(CreateQuestionsSet);
let header = [{ name: "heading" }];
