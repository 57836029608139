export default {
  Dashboard: "لوحة القيادة",
  Users: "المستخدمون",
  Decisions: "القرارات",
  Response: "الاستجابة",
  "User List": "قائمة المستخدمين",
  Applications: "الطلبات",
  Verified: "تم التحقق",
  Unverified: "غير متحقق",
  Dump: "إلقاء",
  "Create User": "إنشاء مستخدم",
  "Question & Answers": "الأسئلة والأجوبة",
  "Create Set": "إنشاء مجموعة",
  "Create Decision": "إنشاء قرار",
  "All Decisions": "جميع القرارات",
  "Decision Responses": "ردود القرار",
  Menu: "قائمة",
  Admin: "المشرف",
  Search: "بحث",
  "My Account": "حسابي",
  Logout: "تسجيل الخروج",
  "Verified Users": "المستخدمون الموثقون",
  "User Id": "معرف المستخدم",
  Questions: "الأسئلة",
  Calculations: "العمليات الحسابية",
  Eligibilty: "الأهلية",
  "View Answers": "عرض الإجابات",
  "Edit/Delete": "تحرير/حذف",
  "Dump Users": "تفريغ المستخدمين",
  "Approved/Hold": "تمت الموافقة/في الانتظار",
  "Create User": "إنشاء مستخدم",
  "Last Name": "الاسم الأخير",
  "First Name": "الاسم الأول",
  DOB: "تاريخ الميلاد",
  Email: "البريد الإلكتروني",
  Password: "كلمة المرور",
  "ID number": "رقم الهوية",
  "Choose Option": "اختر الخيار",
  "Mobile Number": "رقم الجوال",
  Submit: "ارسال",
  Moderater: "مشرف",
  Admin: "مدير",
  User: "مستخدم",
  "Main Name": "الاسم الرئيسي",
  "Questions List": "قائمة الأسئلة",
  Heading: "عنوان",
  Question: "سؤال",
  Type: "نوع",
  Values: "قيم",
  Action: "إجراء",
  "Add New Question": "إضافة سؤال جديد",
  None: "لا شيء",
  TextBox: "مربع النص",
  "Boolean Value": "قيمة منطقية",
  CheckBoxes: "مربعات الاختيار",
  "Increase Decrease": "زيادة/تقليل",
  "Add to form": "اضف الى النماذج",
  "All List Of Questions": "كل قائمة الأسئلة",
  "Add Selected To New List": "إضافة المحدد إلى قائمة جديدة",
  "All List Of Sets": "كل قائمة الأوضاع",
  heading: "عنوان",
  "Set name": "اسم الوضع",
  "Create Decision": "إنشاء قرار",
  "Select Set": "اختيار الوضع",
  "List Of Decisions": "قائمة القرارات",
  "Search Decision from here": "البحث عن قرار من هنا",
  "My Profile": "ملفي الشخصي",
  Role: "الدور",
  Edit: "تحرير",
  "User Dashboard": "لوحة تحكم المستخدم",
  "Loan Management": "إدارة التمويل",
  "Create Type": "إنشاء نوع التمويل",
  "All Loan Applications": "جميع طلبات التمويل",
  Notifications: "الإشعارات",
  "Add Question": "إضافة سؤال",
  "For Success Response": "للرد الناجح",
  "For Error Response": "للرد الخاطئ",
  Description: "الوصف",
  Message: "الرسالة",
  Overview: "نظرة عامة",
  Account: "الحساب",
  Customers: "العملاء",
  "Customers Dashboard": "لوحة تحكم العملاء",
  "All Customers": "جميع العملاء",
  "Verified Customers": "العملاء الذين تم التحقق منهم",
  "Dump Customers": "العملاء المخزنين",
  Administrator: "المسؤول",
  "Create Admin": "إنشاء مسؤول",
  "Create Rights To User": "إنشاء صلاحيات للمستخدم",
  Installments: "الأقساط",
  "Term And Conditions": "الشروط والأحكام",
  Agreement: "الاتفاقية",
  Simah: "السمة",
  calculations: "حسابات",
  "Bare Minimum Expense": "الحد الأدنى من النفقات",
  "Terms And Rates": "الشروط والأسعار",
  "Total Deposite Amount": "إجمالي مبلغ الإيداع",
  "Total Withdrawal Amount": "إجمالي مبلغ السحب",
  "Balance In Account": "الرصيد في الحساب",
  "Total Applications": "إجمالي الطلبات",
  "My Applications": "طلباتي",
  "In Process": "قيد العملية",
  Change: "تغيير",
  "Action Center": "مركز الإجراءات",
  "User Activity": "نشاط المستخدم",
  "Applications Status": "حالة الطلبات",
  "Loan Reason": "سبب القرض",
  "Loan Amount": "مبلغ القرض",
  Duration: "المدة",
  "Maturity Date": "تاريخ الاستحقاق",
  "All Applications": "جميع الطلبات",
  Approved: "تمت الموافقة عليه",
  Pending: "قيد الانتظار",
  Rejected: "تم الرفض",
  All: "الكل",
  "Loan Applications": "طلبات التمويل",
  "View Details": "عرض التفاصيل",
  "On Hold": "معلق",
  "Un Verified": "غير موثق",
  "Monthly Activity Of Users": "نشاط المستخدمين الشهري",
  "Recent Activities": "الأنشطة الأخيرة",
  "Users Anti Fraud History": "تاريخ مكافحة الاحتيال للمستخدمين",
  "Performance Score": "نتيجة الأداء",
  "Phone/Email": "الهاتف/البريد الإلكتروني",
  Name: "الاسم",
  "Guardian Name": "اسم الوصي",
  "Id Number": "رقم الهوية",
  Mobile: "الجوال",
  Status: "الحالة",
  Blocked: "محظور",
  Active: "نشط",
  "De Activate": "إلغاء التفعيل",
  Activate: "تفعيل",
  "All Users": "جميع المستخدمين",
  "Search With Id Number": "البحث برقم الهوية",
  Eligible: "مؤهل",
  Pass: "ناجح",
  Fail: "راسب",
  "Not Eligible": "غير مؤهل",
  Profile: "الملف الشخصي",
  "Api Details": "تفاصيل واجهة برمجة التطبيقات (API)",
  "User Info": "معلومات المستخدم",
  "Financial Reports": "التقارير المالية",
  "All Admins and Moderators": "جميع المشرفين والمديرين",
  "Add New User": "إضافة مستخدم جديد",
  "Choose Role": "اختر الدور",
  "User Name": "اسم المستخدم",
  "Add User": "إضافة مستخدم",
  "Create Loan Type": "إنشاء نوع تمويل",
  "Loan Reason": "سبب القرض",
  Tenure: "الضمانات",
  Ratio: "النسبة",
  Months: "الأشهر",
  "All Loan Types": "جميع أنواع التمويل",
  Browse: "تصفح",
  "Supported formates: Ico, PNG": "التنسيقات المدعومة: ICO، PNG",
  "Add More Tenures": "إضافة مزيد من الضمانات",
  "Amount And Taxes As Per Month": "المبلغ والضرائب شهريًا",
  "Processing Fee": "رسوم المعالجة",
  "Vat on Fee": "ضريبة القيمة المضافة على الرسوم",
  Fee: "الرسوم",
  "Installments Products": "منتجات الأقساط",
  "Add New Product": "إضافة منتج جديد",
  Image: "الصورة",
  Title: "العنوان",
  Price: "السعر",
  "Product Image": "صورة المنتج",
  "Create Installment Product": "إنشاء منتج أقساط",
  "Income Bracket": "الفئة الدخلية",
  TO: "إلى",
  "Product Level": "مستوى المنتج",
  "Customer DBR": "نسبة الدين للعميل (DBR)",
  "GDBR (Without MTG)": "GDBR (بدون MTG)",
  "GDBR (Include MTG)": "GDBR (تضمين MTG)",
  "Income Bracket SAR": "فئة الدخل SAR",
  "Customer DBR": "العميل DBR",
  "GDBR (Including MTG)": "GDBR (تضمين MTG)",
  "Net Income": "الدخل الصافي",
  "Delete DBR": "حذف نسبة الدين (DBR)",
  "Are you sure to delete this ?": "هل أنت متأكد من حذف هذا؟",
  "Add DBR": "إضافة نسبة الدين (DBR)",
  "Add Bare Minimum Expense": "إضافة الحد الأدنى من النفقات",
  "Bare Minimum expense Per Person": "النفقات الأدنى اللازمة لكل شخص",
  Expenses: "المصروفات",
  "Admin Fee In Percentage": "رسوم الإدارة بالنسبة المئوية",
  "Annual Rate": "المعدل السنوي",
  "Vat In Percentage": "ضريبة القيمة المضافة بالنسبة المئوية",
  "Flat Rate Monthly": "المعدل الشهري الثابت",
  Term: "المدة",
  "Term Rate": "معدل الفائدة على المدى",
  "Add Terms And Rates": "إضافة الشروط والأسعار",
  "Total Notifications": "إجمالي الإشعارات",
  Clicked: "تم النقر",
  Delivered: "تم التوصيل",
  "Not Click Yet": "لم يتم النقر بعد",
  "Add New Notification": "إضافة إشعار جديد",
  Icon: "الرمز",
  Subject: "الموضوع",
  Content: "المحتوى",
  "Monthly installment": "القسط الشهري",
  "Vat Fee": "رسوم القيمة المضافة",
  "Interest Amount": "مبلغ الفائدة",
  "Are you sure you want to delete ?": "هل أنت متأكد من الحذف؟",
  "Delete User": "حذف المستخدم",
  Cancel: "حذف المستخدم",
  Delete: "حذف",
  "All List Of Questions in this set": "كل قائمة الأسئلة في هذا المجموعة",
  "View All Screens": "عرض جميع الشاشات",
  "Delete Set": "حذف المجموعة",
  Add: "إضافة",
  Id: "المعرف",
  Options: "الخيارات",
  Formula: "الصيغة",
  "Other Questions": "أسئلة أخرى",
  "Text Questions": "أسئلة نصية",
  "Create Agreement": "إنشاء اتفاقية",
  "Create Terms And Conditions": "إنشاء الشروط والأحكام",
  "Terms And Conditions": "الشروط والأحكام",
  Conditions: "الشروط",
  Creditor: "الدائن",
  "Member Name": "اسم العضو",
  "SIMAH Members": "أعضاء سمة",
  Existing: "قائم",
  "SIMAH Product List": "قائمة منتجات سمة",
  "Serial Number": "الرقم التسلسلي",
  Code: "الكود",
  Arabic: "العربية",
  "Product Group": "مجموعة المنتج",
  "Product Category": "فئة المنتج",
  Product: "المنتج",
  "Simah Description": "وصف السمة",
  Issuer: "المصدر",
  Guidlines: "الإرشادات",
  "Close modal": "إغلاق النافذة المنبثقة",
  "Create Bare Minimum Expense": "إنشاء المصروف الأدنى اللازم",
  "Bare Minimum Title": "عنوان المصروف الأدنى اللازم",
  "Bare Minimum Value": "قيمة المصروف الأدنى اللازم",
  "Create Notification": "إنشاء إشعار",
  "Notification Icon": "أيقونة الإشعار",
  "Choose Navigation": "اختيار التنقل",
  none: "لا شيء",
  "Choose User": "اختيار المستخدم",
  "Formula Name": "اسم الصيغة",
  "Add Answers": "إضافة الإجابات",
  "Add Answer to this Question": "إضافة إجابة على هذا السؤال",
  Answers: "الإجابات",
  "Sign in to your account": "تسجيل الدخول إلى حسابك",
  "Forgot password?": "هل نسيت كلمة المرور؟",
  "Remember me": "تذكرني",
  "Don’t have an account yet?": "لا تملك حسابًا بعد؟",
  "Sign up": "سجل",
  "Sign in": "تسجيل الدخول",
  "Enter Your Id Number": "أدخل رقم الهوية الخاص بك",
  Continue: "متابعة",
  "We have sent a code to your ID Number":
    "لقد قمنا بإرسال رمز إلى رقم الهوية الخاص بك",
  "Id Number Verification": "التحقق من رقم الهوية",
  "Verify Account": "التحقق من الحساب",
  "Didn't recieve code?": "لم تستلم الرمز؟",
  "Resend code in": "إعادة إرسال الرمز خلال",
  Resend: "إعادة الإرسال",
  "Change Password": "تغيير كلمة المرور",
  "Re-Type Password": "إعادة كتابة كلمة المرور",
  "You Can Write Your": "يمكنك كتابة كلمة المرور الجديدة هنا",
  "Device Token:": "رمز الجهاز:",
  "Ip Address:": "عنوان IP:",
  "Mac Address:": "عنوان MAC:",
  Sessions: "الجلسات",
  "Device Info": "معلومات الجهاز",
  "Nothing To Show!": "لا شيء لعرضه!",
  "Total Value:": "القيمة الإجمالية:",
  Reference_Id: "المعرف المرجعي:",
  Number: "الرقم:",
  "Due Type:": "نوع الاستحقاق:",
  "Currently Logged In": "مسجل الدخول حاليًا",
  "Logged In Time": "وقت تسجيل الدخول",
  "Logged Out Time": "وقت تسجيل الخروج",
  "Expenses Frequency": "تكرار المصروفات",
  "Bare Minimum Expenses": "المصروفات الدنيا اللازمة",
  "Customer Declared Expenses": "المصروفات المعلنة من قبل العميل",
  "Final Monthly Expenses": "المصروفات الشهرية النهائية",
  id_validity_period_saudi: "مدة صلاحية الهوية سعودي",
  id_validity_period_expat: "مدة صلاحية الهوية مقيم",
  min_age: "الحد الأدنى للعمر",
  max_age: "الحد الأقصى للعمر",
  basic_salary_saudi: "الراتب الأساسي سعودي",
  basic_salary_expat: "الراتب الأساسي مقيم",
  gross_salary_saudi: "الراتب الإجمالي سعودي",
  gross_salary_expat: "الراتب الإجمالي مقيم",
  min_finance_amount_saudi: "الحد الأدنى لمبلغ التمويل سعودي",
  min_finance_amount_expat: "الحد الأدنى لمبلغ التمويل مقيم",
  max_finance_amount_saudi: "الحد الأقصى لمبلغ التمويل سعودي",
  max_finance_amount_expat: "الحد الأقصى لمبلغ التمويل مقيم",
  min_service_length: "الحد الأدنى لمدة الخدمة",
  overall_experience: "الخبرة الإجمالية",
  min_loan_tenure: "الحد الأدنى لمدة القرض",
  max_loan_tenure: "الحد الأقصى لمدة القرض",
  delinquency: "التخلف عن السداد",
  write_off: " الشطب",
  public_notices: "إشعار عام",
  price_by_tenure: "السعر حسب المدة",
  "Policy Name": "اسم السياسة",
  "Policy Value": "قيمة السياسة",
  History: "التاريخ",
  "Check Policy History": "تحقق من تاريخ السياسة",
  "Update Policy": "تحديث السياسة",
  Update: "تحديث",
  Reject: "رفض",
  Approve: "موافقة",
  REJECTED: "مرفوض",
  APPROVED: "موافق",
  "Modified Date": "تاريخ التعديل",
  "Created Date": "تاريخ الإنشاء",
  "New Value": "القيمة الجديدة",
  "Previous Value": "القيمة السابقة",
  "Policy Name": "اسم السياسة",
  "All Policies": "جميع السياسات",
  "View Policies": "عرض السياسات",
  Policies: "السياسات",
  "Policy History": "تاريخ السياسة",
  "Finance Amount": "مبلغ التمويل",
  "Last Installment Date": "تاريخ القسط الأخير",
  "Net Proceed": "العائد الصافي",
  "Total Amount": "المبلغ الإجمالي",
  "Total Fee": "الرسوم الإجمالية",
  Vat: "ضريبة القيمة المضافة",
  "Admin Fee": "رسوم إدارية",
  "Amortization Rate": "معدل الاستهلاك",
  "Calculated Admin Fee": "الرسوم الإدارية المحسوبة",
  "Calculated Vat": "ضريبة القيمة المضافة المحسوبة",
  "Apr Rate": "معدل النسبة السنوية",
  "Emi monthly Installement": "القسط الشهري إيمي",
  "First Installment Date": "تاريخ القسط الأول",
  "Interest Amount": "مبلغ الفائدة",
  Month: "شهر",
  "Monthly Installment": "القسط الشهري",
  "Interest Amount Table": "جدول مبلغ الفائدة",
  "Last Installment Date": "تاريخ القسط الأخير",
  "Outstanding Principal": "الرصيد المتبقي",
  "Principal Amount Table": "جدول مبلغ الأصل",
  "Due Installment Date": "تاريخ الاستحقاق",
  "Re-Payment Schedule": "جدول إعادة السداد",
  "Download as PDF": "تحميل كملف PDF",
  Selaa: "سلعة",
  Transaction: "معاملة",
  Commodity: "سلعة",
  Wallet: "المحفظة",
  "Customers EMI": "أقساط العملاء",
  "Add SMS": "إضافة رسالة نصية",
  DBR: "نسبة الدين إلى الدخل",
  "Gross Salary": "الراتب الإجمالي",
  "Include Mtg": "تضمين القرض العقاري",
  "Simah Liabilities": "الالتزامات في سمة",
  "Total Dependents": "إجمالي المعالين",
  "Final Eligible EMI": "القسط الشهري المؤهل النهائي",
  "Domestic Worker": "العامل المنزلي",
  "Disposable Income": "الدخل التصرفي",
  Children: "الأطفال",
  "Transaction Id": "معرف المعاملة",
  "Transaction Type": "نوع المعاملة",
  "Transaction Currency": "عملة المعاملة",
  "Transaction Amount": "مبلغ المعاملة",
  "Status Check Retry Count": "عدد محاولات التحقق من الحالة",
  "Sender Account": "حساب المرسل",
  "Receiver Account": "حساب المستلم",
  "Emi Detail": "تفاصيل الأقساط",
  "Loan History": "تاريخ القرض",
  "Transaction History": "تاريخ المعاملات",
  "Awareness Messages": "رسائل توعوية",
  "Check Eligibility": "تحقق من الأهلية",
  "View Simah Report": "عرض تقرير سما",
  "Simah Report": "تقرير سمة",
  Amount: "المبلغ",
  "Lended Id": "رقم هوية الدائن",
  "Wallet Name": "اسم المحفظة",
  Certificates: "شهادة",
  "Lender Internal Id": "رقم الهوية الداخلية للدائن",
  "lenders Customer Id": "رقم معرف الدائن",
  "All Ownership Id": "جميع معرفات الملكية",
  "Owner Id": "رقم هوية المالك",
  File: "ملف",
  Transfer: "نقل",
  Radeem: "الاسترداد",
  "Owner Ship File": "ملف الملكية",
  Eligibility: "الاهلية",
  "Selaa History": "تاريخ سلعة",
  owner: "المالك",
  "ownership Id": "معرف الملكية",
  "redeem Allowed": "الاسترداد المسموح به",
  "Selaa Transaction": "معاملة سلعة",
  "Lender Id": "رقم المقرض",
  Balance: "موازنة",
  Locked: "مقفل",
  "Messsage Type": "نوع الرسالة",
  "Language Code": "رمز اللغة",
  "Phone Number": "رقم الهاتف",
  Phone: "الهاتف",
  "Add New Sms or Otp": "إضافة رسالة نصية قصيرة أو OTP جديدة",
  "Sms & Otp": "الرسائل القصيرة ورموز التحقق",
  description: "الوصف",
  "Search With Product Group": "البحث باستخدام مجموعة المنتجات",
  "Search With Code": "ابحث بالرمز",
  "Locked Amount": "المبلغ المحجوز",
  "User Answers": "إجابات المستخدم",
  "Field / Options": "الحقل/الخيارات",
  City: "المدينة",
  Country: "البلد",
  "Mac Address": "عنوان ماك",
  "Reset Counter": "إعادة تعيين العداد",
  "Heading Arabic": "العنوان باللغة العربية",
  "Heading English": "العنوان باللغة الإنجليزية",
  "Question English": "السؤال باللغة الإنجليزية ",
  "Question Arabic": "السؤال باللغة العربية",
  "Set Name In Arabic": "تعيين الاسم باللغة العربية",
  "Set Name In English": "تعيين الاسم باللغة الإنجليزية",
  "Message Type": "نوع الرسالة",
  "Add New Role": "إضافة دور جديد",
  "Select Role": "اختر الدور",
  "Add More Months": "إضافة المزيد من الأشهر",

  "Repayment Account": "حساب السداد",
  "Disbursement Account": "حساب النفقات",
  "Seulah Bank Account Details": " تفاصيل الحساب البنكي لسيولة",
  "Account Title": "اسم الحساب",
  "Account Number": "رقم الحساب",
  IBAN: "ايبان",
  Save: "حفظ",
  "Delete Bank": "حذف البنك",
  "Add SMS And OTP": "إضافة رسالة جديدة أو رمز تحقق",
  Username: "اسم المستخدم",
  "Delete Account Details": "حذف تفاصيل الحساب",
  "Save Changes": "حفظ التغييرات",
  "Discard Changes": "تجاهل التغييرات",
  "Blacklisted countries": "الدول المدرجة في القائمة السوداء",
  "Add Country": "إضافة دولة",
  "Sync Countries": "مزامنة الدول",
  "Country Name English": "اسم الدولة باللغة الإنجليزية",
  "Country Name Arabic": "اسم الدولة باللغة العربية",
  "Country Code": "رمز الدولة",
  Reason: "السبب",
  Action: "الإجراء",
  "Choose Country": "اختيار الدولة",
  "Delete Country": "حذف البلد",
  View: "منظر",
  "Default blacklisted country": "الدولة المدرجة في القائمة السوداء الافتراضية",
  "View Policy": "عرض السياسة",
  Period: "فترة",
  Bucket: "دلو",
  Count: "عدد",
  "Add New Bucket": "إضافة دلو جديد",
  "Valid count is required.": "يجب أن يكون العدد صالحًا.",
  "Bucket is required.": "الدلو مطلوب.",
  "Period is required.": "الفترة مطلوبة.",
  "3 Months": "3 أشهر",
  "6 Months": "6 أشهر",
  "9 Months": "9 أشهر",
  "12 Months": "12 شهرًا",
  "24 Months": "24 شهرا",
  Latest: "أحدث",
  PENDING: "قيد الانتظار",
  "New Values": "قيم جديدة",
  "Previous Values": "القيم السابقة",
  Closed: "مغلق",
  "Not Applicable": "غير قابل للتطبيق",
  Executed: "تم تنفيذه",
  "Not Executed": "لم يتم التنفيذ",
  Review: "مراجعة",
  NOT_APPLICABLE: "غير قابل للتطبيق",
  ACTIVE: "نشيط",
  CLOSED: "مغلق",
  NOT_EXECUTED: "لم يتم التنفيذ",
  EXECUTED: "تم تنفيذه",
  disposable_income: "الدخل المتاح",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
};
