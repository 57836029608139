import { Outlet } from "react-router-dom";

const DecisionPageLayout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default DecisionPageLayout;
