import { useTranslation } from "react-i18next";

function InputTitle({
  title,
  name,
  register,
  errors,
  disabled,
  requiredText,
  pattern,
}) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row justify-between items-center">
      <div className="flex flex-col w-full dark:text-dark0">
        <a>{t(title)}</a>
        <input
          disabled={disabled}
          {...register(name, {
            required: requiredText,
            pattern: pattern,
          })}
          className={`py-2 px-3 ${
            disabled
              ? ""
              : "dark:bg-dark1 dark:text-dark0 outline-none dark:border-dark2 bg-gray-200 border border-gray-300"
          } rounded-lg mt-1`}
        />
        {errors[name] && (
          <span className="text-red-500">{errors[name]?.message}</span>
        )}
      </div>
    </div>
  );
}

export default InputTitle;
