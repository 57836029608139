import React, { useEffect, useState } from "react";
import { Button } from "Components";
import { useTranslation } from "react-i18next";
import * as action from "Services/redux/reducer";
import { useDispatch, useSelector } from "react-redux";

function CreateUser({ setModelOpen, data }) {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.message);
  const error = useSelector((state) => state.error);
  const { t } = useTranslation();

  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (data) {
      setValue(data?.expenseBareableAmount);
      setTitle(data?.expense);
      setDescription(data?.description);
    }
  }, [data]);

  useEffect(() => {
    if (message === "Success" && error === false) {
      setModelOpen(false);
    }
  }, [message, error]);

  const validateFields = () => {
    const newErrors = {};
    if (!title) newErrors.title = "Title is required!";
    if (!value || isNaN(value))
      newErrors.value = "Bare Minimum Value must be a valid number!";
    if (!description) newErrors.description = "Description is required!";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      const temp = {
        description,
        expense: title,
        expenseBareableAmount: parseFloat(value),
      };
      if (data) {
        const updatedTemp = {
          ...temp,
          id: data?.id,
        };
        dispatch({ type: "UPDATE_EXPENSE", payload: updatedTemp });
      } else {
        dispatch({ type: "ADD_NEW_EXPENSE", payload: temp });
      }
      setTimeout(() => setModelOpen(false), 700);
    } else {
      dispatch(
        action.Message({
          message: "All fields are required!",
          open: true,
          error: true,
        })
      );
    }
  };

  const handleTitleChange = (e) => {
    const inputValue = e;
    setTitle(inputValue);
    if (inputValue) {
      setErrors((prev) => ({ ...prev, title: "" }));
    }
  };

  const handleValueChange = (e) => {
    let newValue = e.target.value;
    if (newValue !== "") {
      newValue = Math.abs(newValue);
      if (!isNaN(newValue)) {
        setValue(newValue);
        setErrors((prev) => ({ ...prev, value: "" }));
      }
    } else {
      setValue("");
      setErrors((prev) => ({
        ...prev,
        value: "Bare Minimum Value must be a valid number!",
      }));
    }
  };

  const handleDescriptionChange = (e) => {
    const inputValue = e;
    setDescription(inputValue);
    if (inputValue) {
      setErrors((prev) => ({ ...prev, description: "" }));
    }
  };

  const isButtonDisabled = () => {
    return (
      !title ||
      !value ||
      isNaN(value) ||
      !description ||
      Object.values(errors).some((error) => error)
    );
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="items-center justify-center flex flex-col"
    >
      <div className="bg-white dark:bg-dark2  rounded shadow-sm flex flex-col lg:flex-row w-full lg:w-max lg:space-x-20 lg:px-20 px-4 py-5">
        <div className="flex flex-col">
          <div className="w-full md:w-96 flex-col mt-5 space-y-6">
            <InputField
              id="firstName"
              heading={t("Bare Minimum Title")}
              value={title}
              onChange={handleTitleChange}
              error={errors.title}
            />

            <InputField2
              heading={t("Bare Minimum Value")}
              value={value}
              onChange={handleValueChange}
              error={errors.value}
            />

            <InputField
              heading={t("Description")}
              value={description}
              onChange={handleDescriptionChange}
              error={errors.description}
            />
          </div>
          <div className="flex flex-row justify-end mt-10 mb-10 px-14">
            <Button
              type="submit"
              buttonValue={t("Submit")}
              buttonStyle="px-20 py-2 w-full"
              disabled={isButtonDisabled()}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

function InputField({ heading, value, onChange, type, error }) {
  return (
    <div className="flex flex-col w-full">
      <label className="text-sm text-gray-700 dark:text-dark0">{heading}</label>
      <input
        type={type || "text"}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={`border rounded-md px-3 py-1.5 outline-none mt-2 w-full dark:bg-dark1 dark:text-dark0  dark:border-dark3 ${
          error ? "border-red-500" : "border-gray-300"
        }`}
      />
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
    </div>
  );
}

function InputField2({ heading, value, onChange, type, error }) {
  return (
    <div className="flex flex-col w-full">
      <label className="text-sm text-gray-700 dark:text-dark0">{heading}</label>
      <input
        type={type || "number"}
        value={value}
        onChange={onChange}
        className={`border rounded-md px-3 py-1.5 outline-none mt-2 w-full dark:bg-dark1 dark:text-dark0  dark:border-dark3 ${
          error ? "border-red-500" : "border-gray-300"
        }`}
      />
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
    </div>
  );
}

export default CreateUser;
