import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Table from "Components/Table";

function Calculations() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [data, setData] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const UserId = queryParams.get("user");
  const getAllUsersEmi = useSelector((state) => state.getAllUsersEmi);
  const loading = useSelector((state) => state.Loading);
  useEffect(() => {
    getAllUsersEmiData();
  }, []);
  function getAllUsersEmiData() {
    dispatch({
      type: "GET_ALL_USERS_EMI",
    });
  }
  useEffect(() => {
    if (getAllUsersEmi?.length > 0) {
      const EmiDetail = getAllUsersEmi?.filter(
        (user) => user?.userId === parseInt(UserId)
      );

      setData(EmiDetail);
    }
  }, [getAllUsersEmi]);

  return (
    <div className=" w-full pb-10">
      {data?.length > 0 ? (
        <div className="overflow-x-auto relative  ">
          <Table header={header} styleMain={"mt-0"}>
            <tbody className="dark:text-dark0">
              {data?.map((v, k) => (
                <tr
                  key={k}
                  className={`border-b   border-gray-100 dark:border-dark1 ${
                    k % 2
                      ? "bg-gray-50 dark:bg-gray-600"
                      : "bg-white dark:bg-gray-700"
                  }`}
                >
                  <td scope="row" className="px-3 py-4">
                    {v?.userId}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.grossSalary || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.includeMtg === true ? "True" : "False"}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.netIncome || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.simahLibilities || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.totalDependents || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.finalEligibleEMI || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.domesticWorker || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.disposableIncome || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.children || "NAN"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="  items-center text-center mt-5 text-lg py-5 text-gray-600">
          {data?.length === 0 && loading ? (
            "Loading ..."
          ) : (
            <a className="text-xl">No data found !</a>
          )}
        </div>
      )}
    </div>
  );
}
export default Calculations;
let header = [
  { name: "User Id" },
  { name: "Gross Salary" },
  { name: "Include Mtg" },
  { name: "Net Income" },
  { name: "Simah Liabilities" },
  { name: "Total Dependents" },
  { name: "Final Eligible EMI" },
  { name: "Domestic Worker" },
  { name: "Disposable Income" },
  { name: "Children" },
];
