import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetLoanHistoryOfUser } from "Services/OtherApis";
import Table from "Components/Table";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6"; // Combined import

function ViewLoanHistory({ appId }) {
  const { t } = useTranslation();

  // Initialize currentPage to 0 (0-based indexing)
  const [loading, setLoading] = useState(false);
  const [paginationContent, setPaginationContent] = useState({
    totalElements: 0,
    totalPages: 0,
    size: 10,
    currentPage: 0, // Changed from 1 to 0
  });
  const [data, setData] = useState([]);

  // Fetch data based on the current page (0-based)
  const fetchData = (pageNumber) => {
    setLoading(true);
    GetLoanHistoryOfUser({
      applicationId: appId,
      pageNumber: pageNumber, // 0-based page number
      pageSize: paginationContent.size,
    })
      .then((res) => {
        if (res?.status === 200) {
          console.log("Response:", res);
          setPaginationContent({
            totalElements: res?.response?.data?.totalElements,
            totalPages: res?.response?.data?.totalPages,
            size: res?.response?.data?.size,
            currentPage: pageNumber, // Set to the current 0-based page
          });
          setData(res?.response?.data?.content || []);
        } else {
          setData([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setData([]);
        setLoading(false);
      });
  };

  // Fetch the first page (page 0) when the component mounts or appId changes
  useEffect(() => {
    fetchData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId]);

  // Handle page changes with 0-based indexing
  const handlePageChange = (page) => {
    console.log("Navigating to page:", page);
    if (page >= 0 && page < paginationContent.totalPages) {
      fetchData(page);
    }
  };

  return (
    <div className="" style={{ minWidth: "500px", maxWidth: "1000px" }}>
      <div className="overflow-x-auto relative mx-4  h-[70vh]">
        {data?.length > 0 ? (
          <Table header={header}>
            <tbody className="dark:text-dark0">
              {data?.map((v, k) => (
                <tr
                  key={k}
                  className={`border-b   border-gray-100 dark:border-dark1 ${
                    k % 2
                      ? "bg-gray-50 dark:bg-gray-600"
                      : "bg-white dark:bg-gray-700"
                  }`}
                >
                  {/* Table Data Cells */}
                  <td className="px-3 py-3">{v?.vat}</td>
                  <td className="px-3 py-3">{v?.term}</td>
                  <td className="px-3 py-3">{v?.flag ? "true" : "false"}</td>
                  <td className="px-3 py-3">{v?.rev}</td>
                  <td className="px-3 py-3">{v?.userId || "NAN"}</td>
                  <td className="px-3 py-3">{v?.status}</td>
                  <td className="px-3 py-3">{v?.aprRate}</td>
                  <td className="px-3 py-3">{v?.termRate}</td>
                  <td className="px-3 py-3">{v?.adminFee}</td>
                  <td className="px-3 py-3">{v?.totalFee}</td>
                  <td className="px-3 py-3">{v?.netProceed}</td>
                  <td className="px-3 py-3">{v?.totalAmount}</td>
                  <td className="px-3 py-3">{v?.annualRate}</td>
                  <td className="px-3 py-3">{v?.financeAmount}</td>
                  <td className="px-3 py-3">{v?.calculatedVat}</td>
                  <td className="px-3 py-3">{v?.interestAmount}</td>
                  <td className="px-3 py-3">{v?.effectiveRate}</td>
                  <td className="px-3 py-3">{v?.monthlyFlatRate}</td>
                  <td className="px-3 py-3">{v?.timeStamp}</td>
                  <td className="px-3 py-3">{v?.amortizationRate}</td>
                  <td className="px-3 py-3">{v?.calculatedAdminFee}</td>
                  <td className="px-3 py-3">{v?.loanTypeId}</td>
                  <td className="px-3 py-3">{v?.submittedDate}</td>
                  <td className="px-3 py-3">{v?.emimonthlyInstallment}</td>
                  <td className="px-3 py-3">{v?.createdDate || "NAN"}</td>
                  <td className="px-3 py-3">{v?.modifiedDate || "NAN"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="w-[700px] items-center dark:text-dark0 text-center mt-5 text-lg py-5 text-gray-600">
            {data?.length === 0 && loading ? (
              "Loading ..."
            ) : (
              <span className="text-xl dark:text-dark0">No data found!</span>
            )}
          </div>
        )}
      </div>

      {/* Pagination Controls */}
      <div className="items-center justify-center flex">
        {paginationContent.totalPages > 1 && (
          <div className="flex space-x-14 rtl:space-x-reverse items-center px-10 py-4">
            {/* Previous Button */}
            <button
              onClick={() =>
                handlePageChange(paginationContent.currentPage - 1)
              }
              disabled={paginationContent.currentPage === 0} // Disable if on the first page
              aria-label="Previous Page"
              className="text-gray-500 dark:text-dark0 items-center flex justify-center disabled:opacity-50 space-x-0.5"
            >
              <FaChevronLeft className="text-xs" style={{ paddingTop: 1 }} />
              <span>Prev</span> {/* Changed from <a> to <span> for semantics */}
            </button>

            {/* Page Numbers */}
            <div className="flex gap-2">
              {Array.from(
                { length: paginationContent.totalPages },
                (_, index) => (
                  <div
                    onClick={() => handlePageChange(index)}
                    key={index}
                    className={`h-10 w-10 cursor-pointer ${
                      paginationContent.currentPage === index
                        ? "bg-gray-400 text-white"
                        : "bg-white text-gray-700 hover:bg-gray-200"
                    } rounded-full flex items-center justify-center transition-colors duration-200`}
                    aria-label={`Go to page ${index + 1}`} // Accessibility improvement
                  >
                    {index + 1} {/* Displayed as 1-based page numbers */}
                  </div>
                )
              )}
            </div>

            {/* Next Button */}
            <button
              onClick={() =>
                handlePageChange(paginationContent.currentPage + 1)
              }
              disabled={
                paginationContent.currentPage ===
                paginationContent.totalPages - 1
              } // Disable if on the last page
              aria-label="Next Page"
              className="text-md dark:text-dark0 text-gray-500 items-center flex justify-center disabled:opacity-50 space-x-0.5"
            >
              <span>Next</span> {/* Changed from <a> to <span> for semantics */}
              <FaChevronRight className="text-xs" style={{ paddingTop: 1 }} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewLoanHistory;

// Header Array (Unchanged)
const header = [
  { name: "Vat" },
  { name: "Term" },
  { name: "Flag" },
  { name: "Rev" },
  { name: "User Id" },
  { name: "Status" },
  { name: "Apr Rate" },
  { name: "Term Rate" },
  { name: "Admin Fee" },
  { name: "Total Fee" },
  { name: "Net Proceed" },
  { name: "Total Amount" },
  { name: "Annual Rate" },
  { name: "Finance Amount" },
  { name: "Calculated Vat" },
  { name: "Interest Amount" },
  { name: "Effective Rate" },
  { name: "Monthly Flat Rate" },
  { name: "Time Stamp" },
  { name: "Amortization Rate" },
  { name: "Calculated Admin Fee" },
  { name: "Loan Type Id" },
  { name: "Submitted Date" },
  { name: "Emi Monthly Installment" },
  { name: "Created Date" },
  { name: "Modified Date" },
];
