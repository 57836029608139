import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Model from "Components/Model2";
import { useLocation } from "react-router-dom";
import * as action from "Services/redux/reducer";

import { GetSeelaInvestMent } from "Services/OtherApis";
import { getLanguage } from "functions/getLanguage";
import Table from "Components/Table";
function SeelaInvestment() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [modelOpen, setModelOpen] = useState(false);
  const [modelOpen2, setModelOpen2] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const UserId = queryParams.get("user");
  const [activeData, setActiveData] = useState();
  const [activeData2, setActiveData2] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    GetSelaInvestmentsData();
  }, []);

  function GetSelaInvestmentsData() {
    setLoading(true);
    GetSeelaInvestMent(UserId)
      .then((data) => {
        // dispatch(
        //   action.Message({ open: true, message: "Success", error: false })
        // );
        setLoading(false);
        setData(data?.data);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(action.Message({ open: true, message: "Error", error: true }));
      });
  }

  let header = [
    { name: "Application Id" },
    { name: "Amount" },
    { name: "Status" },
    { name: "Owner ship Id" },
    { name: "Certificates" },
    { name: "View" },
    { name: "Action" },
    { name: "Action" },
  ];

  return (
    <div className=" w-full pb-10">
      {data?.length > 0 ? (
        <div className="overflow-x-auto relative  ">
          <Table header={header} styleMain={"mt-0"}>
            <tbody className="dark:text-dark0">
              {data?.map((v, k) => (
                <tr
                  key={k}
                  className={`border-b   border-gray-100 dark:border-dark1 ${
                    k % 2
                      ? "bg-gray-50 dark:bg-gray-600"
                      : "bg-white dark:bg-gray-700"
                  }`}
                >
                  <td scope="row" className="px-3 py-4">
                    {v?.applicationId}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.amount}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.status}
                  </td>

                  <td scope="row" className="px-3 py-4">
                    {JSON.parse(v?.buyResponse)?.ownershipId}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {JSON.parse(v?.buyResponse)?.certificates}
                  </td>
                  <td className="px-3">
                    {v?.ownershipFileUrl ? (
                      <div
                        onClick={() =>
                          window.open(v?.ownershipFileUrl, "_blank", "noopener")
                        }
                        className="w-max px-3 py-1 rounded-md text-white bg-green-500 cursor-pointer hover:opacity-80 duration-200"
                      >
                        {t("View Investment Certificate")}
                      </div>
                    ) : (
                      "Not Found"
                    )}
                  </td>
                  <td className="px-3">
                    <div
                      className="w-max px-3 py-1 rounded-md text-white bg-green-500 cursor-pointer hover:opacity-80 duration-200"
                      onClick={() => (
                        setActiveData2(JSON.parse(v?.buyRequest)),
                        setModelOpen2(true)
                      )}
                    >
                      {t("View Request")}
                    </div>
                  </td>
                  <td className="px-3">
                    <div
                      className="w-max px-3 py-1 rounded-md text-white bg-green-500 cursor-pointer hover:opacity-80 duration-200"
                      onClick={() => (
                        setActiveData(JSON.parse(v?.buyResponse)),
                        setModelOpen(true)
                      )}
                    >
                      {t("View Response")}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="  items-center dark:text-dark0 text-center mt-5 text-lg py-5 text-gray-600">
          {data?.length === 0 && loading ? (
            "Loading ..."
          ) : (
            <a className="text-xl dark:text-dark0">No data found !</a>
          )}
        </div>
      )}

      {modelOpen ? (
        <Model
          setModelOpen={(e) => setModelOpen(e)}
          reset={() => (setModelOpen(false), setActiveData(""))}
          heading="View Response"
        >
          <Response data={activeData} />
        </Model>
      ) : null}
      {modelOpen2 ? (
        <Model
          setModelOpen={(e) => setModelOpen2(e)}
          reset={() => (setModelOpen2(false), setActiveData2(""))}
          heading="View Request"
        >
          <Response data={activeData2} />
        </Model>
      ) : null}
    </div>
  );
}
export default SeelaInvestment;

function Response({ data }) {
  const { t } = useTranslation();

  return (
    <div
      className="px-5 py-4 flex flex-row pb-7 overflow-auto"
      style={{ maxHeight: "85vh" }}
    >
      <div className="w-44 space-y-4 flex flex-col opacity-80">
        {Object.keys(data || {}).map((key, index) => (
          <a key={index}>{t(key)} :</a>
        ))}
      </div>
      <div className="w-72 space-y-4 flex flex-col">
        {Object.values(data || {}).map((value, index) => (
          <a
            key={index}
            className="overflow-hidden text-ellipsis whitespace-nowrap"
          >
            {value}
          </a>
        ))}
      </div>
    </div>
  );
}
