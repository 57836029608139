import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CardMain from "Components/Cards/main";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import withAuthorization from "constants/authorization";

import Table from "Components/Table";
function AllUsers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("user");

  const loanReasons = useSelector((state) => state.getAllLoanReasons);
  const getSingleLoanTypeEmi = useSelector(
    (state) => state.getSingleLoanTypeEmi
  );
  const [active, setActive] = useState("All");
  const [setId, setSetId] = useState();

  const message = useSelector((state) => state.message);
  const error = useSelector((state) => state.error);

  useEffect(() => {
    getAllReasons();
  }, []);

  function getAllReasons() {
    dispatch({
      type: "GET_ALL_LOAN_REASONS",
    });
  }
  useEffect(() => {
    if (loanReasons[0]) {
      setActive(loanReasons[0]?.loanTypeDetail?.reason);
      setSetId(loanReasons[0]?.loanTypeDetail?.id);
    }
  }, [loanReasons]);

  useEffect(() => {
    if (setId) {
      dispatch({
        type: "GET_USER_LOAN_EMI",
        payload: { userId: userId, setId: setId },
      });
    }
  }, [setId]);

  let header = [
    { name: "final Eligible EMI" },
    { name: "monthly Installment" },
    { name: "principal Loan Amount" },
    { name: "profit" },
    { name: "tenure In Months" },
    { name: "term Rate" },
    { name: "total Loan Amount" },
    { name: "total Loan Repayable" },
    { name: "Action" },
  ];

  return (
    <div className="py-5">
      <div className="flex flex-row space-x-3 overflow-x-scroll		">
        {loanReasons?.map((v, k) => {
          return (
            <div
              onClick={() => (
                setActive(v?.loanTypeDetail?.reason),
                setSetId(v?.loanTypeDetail?.id)
              )}
              className={` w-max  px-3 py-1 cursor-pointer hover:opacity-80 rounded-md ${
                active === v?.loanTypeDetail?.reason
                  ? "bg-blue-500 text-white "
                  : " border-gray-300 border text-gray-600 dark:text-dark0"
              }`}
            >
              <div className="w-max "> {v?.loanTypeDetail?.reason}</div>
            </div>
          );
        })}
      </div>
      {error === false && message === "successEmi" ? (
        <CardMain
          headerDisable={true}
          width="w-full mt-6"
          iconStyle="text-3xl text-primary"
        >
          <div className="overflow-x-auto relative ">
            <Table header={header} styleMain={"mt-0"}>
              <tbody className="dark:text-dark0">
                {getSingleLoanTypeEmi?.map((v, k) => (
                  <tr
                    key={k}
                    className={`border-b   border-gray-100 dark:border-dark1 ${
                      k % 2
                        ? "bg-gray-50 dark:bg-gray-600"
                        : "bg-white dark:bg-gray-700"
                    }`}
                  >
                    <td
                      scope="row"
                      className="px-3 py-4 flex flex-row space-x-3 items-center rtl:space-x-reverse"
                    >
                      <a>{v?.finalEligibleEMI}</a>
                    </td>
                    <td>{v?.monthlyInstallment}</td>
                    <td>{v?.principalLoanAmount}</td>
                    <td>{v?.profit}</td>
                    <td>{v?.tenureInMonths}</td>
                    <td>{v?.termRate}</td>
                    <td>{v?.totalLoanAmount}</td>
                    <td>{v?.totalLoanRepayable}</td>
                    <td>
                      {" "}
                      <div
                        onClick={() =>
                          navigate(
                            `/finance-amount-calculations?financeAmount=${v?.totalLoanAmount}&term=${v?.tenureInMonths}&userId=${userId}`
                          )
                        }
                        className="py-1 px-3 rounded-md bg-blue-500 w-max text-white cursor-pointer hover:opacity-80"
                      >
                        Term Rate Calculations
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardMain>
      ) : (
        <div className="py-20 px-20 text-center w-full border border-gray-300 rounded-sm mt-6 dark:text-dark0">
          <a>{"Nothing To Show!"}</a>
        </div>
      )}
    </div>
  );
}
export default withAuthorization(AllUsers);
