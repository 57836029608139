import Table from "Components/Table";
import React from "react";
import { useSelector } from "react-redux";

function SimahMembers() {
  const codes = useSelector((state) => state.getSimahCodes);

  let header = [{ name: "Id" }, { name: "Creditor" }, { name: "Member Name" }];
  return (
    <div className="  w-full">
      <div className="overflow-x-auto relative">
        <Table header={header} styleMain={"mt-0"}>
          <tbody className="dark:text-dark0">
            {codes?.["SIMAH Members"]?.map((v, k) => (
              <tr
                key={k}
                className={`border-b   border-gray-100 dark:border-dark1 ${
                  k % 2
                    ? "bg-gray-50 dark:bg-gray-600"
                    : "bg-white dark:bg-gray-700"
                }`}
              >
                <td
                  scope="row"
                  className="px-10 py-4 flex flex-row space-x-3 items-center rtl:space-x-reverse"
                >
                  <a>{v.id}</a>
                </td>

                <td className="px-3 py-4">{v.creditor}</td>
                <td className="px-3 py-4">{v.memberName}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
export default SimahMembers;
