import Table from "Components/Table";
import React from "react";

function PersonalDetailCard({ data = [] }) {
  let header = [
    { name: "Question" },
    { name: "Type" },
    { name: "Field / Options" },
    { name: "User Answers" },
  ];

  return (
    <div className="w-full flex flex-col  overflow-x-auto">
      <Table header={header} styleMain="mt-0 mb-2">
        <tbody className="dark:text-dark0">
          {data?.length > 0 &&
            data?.map((v, k) => (
              <tr
                key={k}
                className={`border-b   dark:border-dark1 ${
                  k % 2
                    ? "bg-gray-50 dark:bg-gray-600"
                    : "bg-white dark:bg-gray-700"
                }`}
              >
                <td className="px-2 py-4 overflow-wrap ">{v?.question}</td>

                <td className="px-2 py-4">{v?.type}</td>
                <td className="px-2 py-4">
                  <a>
                    {v?.options?.length > 0 ? v?.options?.join(", ") : v?.field}
                  </a>
                </td>
                <td className="px-2 py-4">
                  <a>
                    {v?.userAnswer?.length > 0
                      ? v?.userAnswer?.join(", ")
                      : v?.field}
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
}
export default PersonalDetailCard;
