import React, { useEffect, useState } from "react";
import CardMain from "Components/Cards/main";
import { Button } from "Components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TextEditor from "./textEditor";
import { CreateNotification } from "Services/OtherApis";
import * as action from "Services/redux/reducer";

function CreateNotificationPage({ CloseModel }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getScreenName = useSelector(
    (state) => state.getScreenName?.appFlow?.screenFlow || []
  );
  const user = useSelector((state) => state.getUserById);
  const tokens = useSelector((state) => state.getDevicesTokens);

  const [checked, setChecked] = useState(false);
  const [description, setDescription] = useState("");
  const [subject, setSubject] = useState("");
  const [mainUserId, setMainUserId] = useState("");
  const [navigation, setNavigation] = useState("none");
  const [allTokens, setAllTokens] = useState("");

  useEffect(() => {
    if (user?.deviceInfo?.deviceToken) {
      setAllTokens(user?.deviceInfo?.deviceToken);
    }
  }, [user]);

  useEffect(() => {
    dispatch({ type: "GET_SCREENS", payload: 222 });
    dispatch({ type: "GET_ALL_DEVICES_TOKENS" });
  }, [dispatch]);

  const handleSubmit = () => {
    if (subject && description && navigation !== "none") {
      if (!checked && !mainUserId) {
        alert("All fields are required!");
        return;
      }

      const payload = {
        subject,
        content: description,
        navigation,
        tokens: checked ? allTokens : [mainUserId],
      };

      CreateNotification(payload).then((res) => {
        if (res.status === 200) {
          dispatch(
            action.Message({
              message: res?.message,
              open: true,
              error: false,
            })
          );
          CloseModel(false);
        } else {
          dispatch(
            action.Message({
              message: res.message,
              open: true,
              error: true,
            })
          );
        }
      });
    } else {
      alert("All fields are required!");
    }
  };

  const handleAllTokens = () => {
    const tokensValue = tokens?.map((item) => item?.deviceToken);
    setAllTokens(tokensValue);
  };

  return (
    <div className="items-center flex flex-col">
      <div className="bg-gray-20">
        <CardMain width="w-full">
          <div className="flex md:flex-row flex-col md:space-x-20 rtl:space-x-reverse">
            <div className="w-full space-y-5">
              <InputField
                placeholder="Subject"
                heading={t("Subject")}
                value={subject}
                onChange={setSubject}
              />
              <Select
                data={getScreenName}
                heading={t("Choose Navigation")}
                value={navigation}
                onChange={setNavigation}
              />
              <div className="flex flex-row justify-between items-center ">
                <div className="w-1/2">
                  <Users
                    value={mainUserId}
                    checked={checked}
                    usersData={tokens}
                    setMainUserId={setMainUserId}
                  />
                </div>
                <div className="flex flex-col">
                  <a className="text-sm text-gray-700 dark:text-dark0 ">
                    {t("All Users")}
                  </a>
                  <input
                    type="checkbox"
                    className="h-5 w-5 mt-3"
                    checked={checked}
                    onChange={() => {
                      setMainUserId("");
                      setChecked(!checked);
                      handleAllTokens();
                    }}
                  />
                </div>
              </div>
              <Description
                heading={t("Content")}
                handleChange={setDescription}
              />
            </div>
          </div>
          <div className="flex flex-row justify-end mt-10">
            <Button
              onButtonClick={handleSubmit}
              type="submit"
              buttonValue={t("Submit")}
              buttonStyle="px-14 py-2 w-full md:w-max"
            />
          </div>
        </CardMain>
      </div>
      <div className="md:mt-0 mt-5 bg-gray-200 xl:w-2/5 lg:w-1/2 md:w-full"></div>
    </div>
  );
}

export default CreateNotificationPage;

function Users({ usersData, setMainUserId, mainUserId, checked, value }) {
  const { t } = useTranslation();
  return (
    <div>
      <Select2
        value={mainUserId} // Pass mainUserId directly her
        checked={checked}
        data={usersData}
        heading={t("Choose User")}
        onChange={setMainUserId}
      />
    </div>
  );
}

function Select2({ heading, value, onChange, data, checked }) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col w-full">
      <a className="text-sm text-gray-700 dark:text-dark0">{heading}</a>
      <select
        disabled={checked}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        className="border-gray-300 border rounded-md px-3 py-1.5 outline-none mt-2 w-full dark:bg-dark1 dark:text-dark0  dark:border-dark3"
      >
        {data?.map((option, index) => (
          <option key={index} value={option.deviceToken}>
            {t("user Id : " + option.userId)}
          </option>
        ))}
      </select>
    </div>
  );
}

function Select({ heading, value, onChange, data }) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  return (
    <div className="flex flex-col w-full">
      <a className="text-sm text-gray-700 dark:text-dark0">{heading}</a>
      <select
        style={{ direction: isRTL ? "rtl" : "ltr" }}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        className={` border-gray-300 border rounded-md px-3 py-1.5 outline-none mt-2 w-full dark:bg-dark1 dark:text-dark0  dark:border-dark3 ${
          isRTL ? "text-right pr-8" : ""
        }`}
      >
        <option value="none">{t("none")}</option>
        {data.map((option, index) => (
          <option key={index} value={option.name}>
            {t(option.name)}
          </option>
        ))}
      </select>
    </div>
  );
}

function InputField({ heading, value, onChange, type = "text", placeholder }) {
  return (
    <div className="flex flex-col w-full">
      <a className="text-sm text-gray-700 dark:text-dark0">{heading}</a>
      <input
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="border-primary border rounded-md px-3 py-1.5 outline-none mt-2 w-full dark:bg-dark1 dark:text-dark0  dark:border-dark3"
      />
    </div>
  );
}

function Description({ heading, handleChange }) {
  return (
    <div className="flex flex-col w-full">
      <a className="text-sm text-gray-700">{heading}</a>
      <TextEditor handleChange={handleChange} />
    </div>
  );
}
