import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as action from "Services/redux/reducer";
import { TransactionHistory } from "Services/OtherApis";
import Table from "Components/Table";
import Model from "Components/Model2";

function TransactionDetail() {
  const [activeData, setActiveData] = useState();
  const [modelOpen, setModelOpen] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [data, setData] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const UserId = queryParams.get("user");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    TransactionHistoryFunction();
  }, []);

  function TransactionHistoryFunction() {
    setLoading(true);
    TransactionHistory(UserId)
      .then((data) => {
        setData(data?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(action.Message({ open: true, message: "Error", error: true }));
      });
  }

  function formatXml(xml) {
    const PADDING = "  ";
    const reg = /(>)(<)(\/*)/g;
    let formatted = "";
    xml = xml.replace(reg, "$1\r\n$2$3");
    let pad = 0;

    xml.split("\r\n").forEach((node) => {
      let indent = 0;
      if (node.match(/.+<\/\w[^>]*>$/)) {
        indent = 0;
      } else if (node.match(/^<\/\w/)) {
        if (pad !== 0) {
          pad -= 1;
        }
      } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
        indent = 1;
      } else {
        indent = 0;
      }

      formatted += PADDING.repeat(pad) + node + "\r\n";
      pad += indent;
    });

    return formatted.trim();
  }
  return (
    <div className=" w-full pb-10">
      {data?.length > 0 ? (
        <div className="overflow-x-auto relative  ">
          <Table header={header} styleMain={"mt-0"}>
            <tbody className="dark:text-dark0">
              {data?.map((v, k) => (
                <tr
                  key={k}
                  className={`border-b   border-gray-100 dark:border-dark1 ${
                    k % 2
                      ? "bg-gray-50 dark:bg-gray-600"
                      : "bg-white dark:bg-gray-700"
                  }`}
                >
                  <td scope="row" className="px-3 py-4">
                    {v?.userId}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.transactionId}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.transactionType}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.transactionCurrency}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.transactionAmount}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.financeAmount}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.statusCheckRetryCount || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.status || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.adminFee || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.vat || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.totalFee || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.senderAccount}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.receiverAccount}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    <div
                      className="w-max px-3 py-1 rounded-md text-white bg-green-500 cursor-pointer hover:opacity-80 duration-200"
                      onClick={() => (
                        setActiveData(v?.paymentInquiryResponse),
                        setModelOpen(true)
                      )}
                    >
                      {t("View Response")}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="  items-center text-center mt-5 text-lg py-5 dark:text-dark0 text-gray-600">
          {data?.length === 0 && loading ? (
            "Loading ..."
          ) : (
            <a className="text-xl dark:text-dark0">No data found !</a>
          )}
        </div>
      )}

      {modelOpen ? (
        <Model
          setModelOpen={(e) => setModelOpen(e)}
          reset={() => (setModelOpen(false), setActiveData(""))}
          heading="Payment Inquiry Response"
        >
          <div
            className="px-5 py-4 flex flex-row pb-7 overflow-auto"
            style={{ maxHeight: "85vh", maxWidth: "100vh" }}
          >
            <pre className="whitespace-pre-wrap font-mono text-xs text-dark-6 dark:text-white">
              {formatXml(activeData)}
            </pre>
          </div>
        </Model>
      ) : null}
    </div>
  );
}
export default TransactionDetail;

let header = [
  { name: "User Id" },
  { name: "Transaction Id" },
  { name: "Transaction Type" },
  { name: "Transaction Currency" },
  { name: "Transaction Amount" },
  { name: "Finance Amount" },
  { name: "Status Check Retry Count" },
  { name: "Status" },
  { name: "Admin Fee" },
  { name: "Vat" },
  { name: "Total Fee" },
  { name: "Sender Account" },
  { name: "Receiver Account" },
  { name: "Payment Inquiry Response" },
];
