import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "../Layouts/MainLayout";
import { routes } from "./routes";
import Profile from "Pages/Customers/StandAlonePages/CustomerDetailProfile";
import MyAccount from "Pages/MyAccount";
import Login from "Pages/authentication/Login";
import ForgetPassword from "Pages/authentication/resetpassword";
import CreateScreens from "Pages/Decision/StandAlonePages/CreateScreenPage";
import ViewScreen from "Pages/Decision/StandAlonePages/ViewScreensPage";
import UserProfile from "Pages/Applications/StandAlonePages/UserLoanApplication";
import MainTemplate from "Layouts/MainTemplate";
import NotFound from "Pages/NotFound";
import UserSimah from "Pages/Customers/StandAlonePages/SimahDownlaod";
import UserEligibility from "Pages/Customers/StandAlonePages/UserEligibility";
import ViewPolicyHistory from "Pages/Policies/StandAlonePages/PolicyHistory/ViewPolicyHistory";
import ViewPolicyHistoryOther from "Pages/Policies/StandAlonePages/PolicyHistory/ViewPolicyHistoryOthers";

function App() {
  return (
    <MainTemplate>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            {routes}
            <Route
              path="/customers/allcustomers/profile"
              element={<Profile />}
            />
            <Route path="/customers/verified/profile" element={<Profile />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route
              path="/decisions/create-set/create-screen"
              element={<CreateScreens />}
            />

            <Route
              path="/decisions/create-set/view-screen"
              element={<ViewScreen />}
            />
            <Route
              path="/customers/verified/simah/usercodes"
              element={<UserSimah />}
            />
            <Route
              path="/customers/allcustomers/user-eligibility"
              element={<UserEligibility />}
            />

            <Route
              path="/policies/view-policies/view-policy-history"
              element={<ViewPolicyHistory />}
            />
            <Route
              path="/policies/view-policies/view-history"
              element={<ViewPolicyHistoryOther />}
            />

            <Route
              path="/applications/loan/user-profile"
              element={<UserProfile />}
            />

            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </MainTemplate>
  );
}

export default App;
