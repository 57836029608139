import { Outlet } from "react-router-dom";

const UsersPageLayout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default UsersPageLayout;
