import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Table from "Components/Table";

function PersonalDetailCard() {
  const user = useSelector(
    (state) => state.getUserByIdWithDetail?.userMonitoring
  );
  const { t } = useTranslation();

  let header = [
    { name: "Logged In Time" },
    { name: "Logged Out Time" },
    { name: "City" },
    { name: "Country" },
    { name: "Ip Address" },
    { name: "Mac Address" },
  ];

  return (
    <div className="overflow-x-auto relative   w-full">
      <Table header={header} styleMain="mt-0">
        <tbody className="dark:text-dark0">
          {user?.map((v, k) => (
            <tr
              key={k}
              className={`border-b   border-gray-100 dark:border-dark1 ${
                k % 2
                  ? "bg-gray-50 dark:bg-gray-600"
                  : "bg-white dark:bg-gray-700"
              }`}
            >
              <td className="px-3 py-3 ">
                {moment(v?.loggedInTime).format("LLLL")}
              </td>
              {v?.loggedOutTime ? (
                <td className="px-3">
                  {" "}
                  {moment(v?.loggedOutTime).format("LLLL")}
                </td>
              ) : (
                <td className="px-3">{t("Currently Logged In")} </td>
              )}
              <td className="px-3">{v?.cityInfo}</td>
              <td className="px-3">{v?.countryInfo}</td>
              <td className="px-3">{v?.ipAddress}</td>
              <td className="px-3">{v?.macAddress}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
export default PersonalDetailCard;
